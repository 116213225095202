import { SENDING_REQUEST, SET_ERROR, CLEAR_ERROR, SET_RESPONSE } from '../actions/constants';

import {
  SET_AUTH,
  VERIFY_USER_FOR_SIGNUP_REQUEST,
  VERIFY_RESET_PASSWORD_REQUEST,
} from '../userAuth/actions/userAuthConstants';

const initialState = {
  networkData: { sending: false },
  authData: { loggedState: false },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SENDING_REQUEST:
      return { ...state, networkData: { ...state.networkData, sending: action.sending } };
    case SET_AUTH:
      return { ...state, authData: { ...state.authData, loggedState: action.loggedState, user: action.user } };
    case SET_ERROR:
      return { ...state, networkData: { ...state.networkData, error: action.error } };
    case CLEAR_ERROR:
      return { ...state, networkData: { ...state.networkData, error: {} } };
    case VERIFY_USER_FOR_SIGNUP_REQUEST:
      return {
        ...state,
        authData: {
          ...state.authData,
          loggedState: action.loggedState,
        },
      };
    case VERIFY_RESET_PASSWORD_REQUEST:
      return { ...state, authData: { ...state.authData, verifiedForResetPassword: action.verified } };
    case SET_RESPONSE:
      return { ...state, networkData: { ...state.networkData, response: action.response } };
    default:
      return state;
  }
}

export default reducer;
