import React from 'react';
import { connect } from 'react-redux';
import { logoutRequest } from '../../actions/userAuthActions';

const Logout = ({ dispatch, authData }) => {
  const { loggedState } = authData;
  function onLogoutClick() {
    dispatch(logoutRequest());
  }

  if (loggedState) {
    return (
      <a href='#' onClick={onLogoutClick}>
        Logout
      </a>
    );
  } else {
    return <div>Error</div>;
  }
};

function mapStateToProps(state) {
  return {
    authData: state.authData,
  };
}

export default connect(mapStateToProps)(Logout);
