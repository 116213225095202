import React from 'react';
import { connect } from 'react-redux';
import Logout from '../../userAuth/components/Logout';

const HomeUser = ({ authData }) => {
  return (
    <>
      <div>
        <h2>Welcome to Mokuroku {authData?.user?.username}!</h2>
      </div>
      <div>We will send you an email when the platform is fully ready.</div>
      <Logout />
    </>
  );
};

function mapStateToProps(state) {
  return {
    authData: state.authData,
  };
}

export default connect(mapStateToProps)(HomeUser);
