import React from 'react';
import LoadingIcon from '../LoadingIcon';
import styles from './LoadingSection.module.scss';

export const LoadingSection = () => {
  return (
    <div className={`${styles.loadingSection}`}>
      <LoadingIcon />
    </div>
  );
};

export default LoadingSection;
