import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderLink from './HeaderLink';
import styles from './Header.module.scss';
import commonStyles from '../../theme/common.module.scss';
import logo from './images/mokuroku-logo.svg';

const Header = ({ authData }) => {
  const guestMenu = (
    <ul className={styles.guestMenu}>
      <li>
        <HeaderLink to='/login'>Login</HeaderLink>
      </li>
      <li>
        <HeaderLink to='/signup'>Signup</HeaderLink>
      </li>
    </ul>
  );
  const userMenu = <div className={styles.guestMenu}>Hello {authData && authData.user && authData.user.username}</div>;

  return (
    <header className={`${styles.mainHeader}`}>
      <nav className={`${styles.mainGuestNav}`}>
        <div className={styles.logoContainer}>
          <Link to='/'>
            <img src={logo} />
          </Link>
        </div>
        {authData && authData.loggedState ? userMenu : guestMenu}
      </nav>
    </header>
  );
};

function mapStateToProps(state) {
  return {
    authData: state.authData,
  };
}

export default connect(mapStateToProps)(Header);
