import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { signupRequest, verifyUserForSignupRequest } from '../../actions/userAuthActions';
import UserInfoForm from '../../../components/UserInfoForm';
import FacebookAuth from '../FacebookAuth';
import { SOCIAL_AUTH_FACEBOOK, CUSTOM_AUTH } from '../../../models/constants';
import { SIGNUP_REQUEST } from '../../actions/userAuthConstants';
import commonStyles from '../../../theme/common.module.scss';
import styles from './Signup.module.scss';
import Button from '../../../components/ui/Button';

//three dev modes:
//to prefill form
const testData = process.env.NODE_ENV === 'development' && true; // change to false when testing without data
//to make a mock request to the API that returns an error
const mockRequestError = '';
//to make a mock request to the API that returns success and mock data
const mockRequestSuccess = '';

export const Signup = ({ dispatch, authData, networkData }) => {
  const prefilledData = {
    fullName: testData ? 'Dev Iris' : '',
    email: testData ? 'iris@iris.com' : '',
    username: testData ? 'superiris' : '',
    country: testData ? 'Mexico' : '',
    password: testData ? 'Admin123' : '',
    passwordConfirmation: testData ? 'Admin123' : '',
    birthDateDD: testData ? '26' : '',
    birthDateMM: testData ? '8' : '',
    birthDateYYYY: testData ? '1982' : '',
    terms: testData ? true : false,
  };
  const serverError =
    networkData.error && networkData.error.type === SIGNUP_REQUEST && networkData.error.message && !mockRequestError
      ? networkData.error.message
      : null;

  const [formValues, setFormValues] = useState(prefilledData);
  const [signupMethod, setSignupMethod] = useState(CUSTOM_AUTH); //custom or FB
  const [formShown, setShowForm] = useState(false); //custom or FB
  const { sending } = networkData;
  const submitForm = (values) => {
    dispatch(signupRequest({ signupMethod, ...authData.user, ...values }, mockRequestSuccess));
  };

  const onFacebookAuthorized = (facebookData) => {
    //when signing up with Facebook, we need to ensure the user hasn't signed up before
    //so we verify if they are eligible for signup
    //if they are not, means they were already there and we can just redirect them to home (by setting the auth, route handles it)
    setSignupMethod(SOCIAL_AUTH_FACEBOOK);
    facebookData.loginMethod = SOCIAL_AUTH_FACEBOOK;
    dispatch(verifyUserForSignupRequest(facebookData, mockRequestError)); //second parameter can be mock, we want this to fail to be able to test the fb signup without removing the app from our FB account each test
    prefillFields(facebookData.user);
    setShowForm(true);
  };

  const prefillFields = (user) => {
    setFormValues({
      ...formValues,
      fullName: user.name,
      email: user.email,
      socialId: user.id,
    });
  };

  const showForm = (e) => {
    setShowForm(true);
    const target = e.target;
    setTimeout(() => {
      window.scroll({
        top: target.getBoundingClientRect().bottom + 10,
        left: 0,
        behavior: 'smooth',
      });
    }, 300);
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);
  if (!authData.loggedState) {
    return (
      <section className={`${commonStyles.mainGrid} ${styles.signup}`}>
        <h1 className={`${styles.title}`}>Signup</h1>
        <div className={styles.instructions}>
          <p>Signup with your Facebook account and avoid setting a password!</p>
          <p>
            Are you already registered? <Link to='/login'>Log in</Link>
          </p>
        </div>
        <FacebookAuth onAuthorized={onFacebookAuthorized}>Signup with Facebook</FacebookAuth>
        <p className={styles.instructions}>or</p>
        <Button className={styles.button} onClick={showForm} disabled={signupMethod === SOCIAL_AUTH_FACEBOOK}>
          Signup with your email
        </Button>
        {formShown ? (
          <UserInfoForm
            onSubmit={submitForm}
            serverError={serverError}
            values={formValues}
            signupMethod={signupMethod}
            loading={sending}
          />
        ) : null}
      </section>
    );
  } else {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }
};

function mapStateToProps(state) {
  return {
    authData: state.authData,
    networkData: state.networkData,
  };
}

//connecting component to the store (https://react-redux.js.org/introduction/basic-tutorial)
export default connect(mapStateToProps)(Signup);
