import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderLink.module.scss';

const HeaderLink = ({ classNames = [], className, children, to, current = false }) => {
  const [disabled, setDisabled] = useState(false);
  className && classNames.push(className);
  const onClick = (e) => {
    //Uncomment when section state is working
    //setDisabled(true);
  };

  return (
    <div className={`${current ? styles.current : ''} ${styles.container}`}>
      {disabled ? (
        <span className={`${classNames.join(' ')} ${styles.link}`}>{children}</span>
      ) : (
        <Link className={`${classNames.join(' ')} ${styles.link}`} to={to} onClick={onClick}>
          {children}
        </Link>
      )}
    </div>
  );
};

export default HeaderLink;
