import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import commonStyles from '../../theme/common.module.scss';
import logo from '../Header/images/mokuroku-logo.svg';
import twitterIcon from './images/icon-twitter.svg';
import facebookIcon from './images/icon-facebook.svg';

const Footer = () => {
  return (
    <footer className={`${styles.mainFooter}`}>
      <nav className={commonStyles.mainGrid}>
        <figure className={styles.logo}>
          <Link to='/'>
            <img src={logo} />
          </Link>
        </figure>

        <ul className={styles.links}>
          <li>
            <Link className={styles.footerNavLink} to='/faq'>
              Frequently asked questions
            </Link>
          </li>
          <li>
            <Link className={styles.footerNavLink} to='/privacy-policy'>
              Privacy policy
            </Link>
          </li>
          <li>
            <Link className={styles.footerNavLink} to='/tandc'>
              Terms and conditions
            </Link>
          </li>
          <li>
            <Link className={styles.footerNavLink} to='/cookies'>
              Cookie policy
            </Link>
          </li>
        </ul>
        <div className={styles.socialMedia}>
          <h3>Get in touch</h3>
          <ul>
            <li>
              <a className={styles.iconButton} href='https://facebook.com/mokuroku'>
                <img src={facebookIcon} alt='Facebook' />
              </a>
            </li>
            <li>
              <a className={styles.iconButton} href='https://twitter.com/mokuroku'>
                <img src={twitterIcon} alt='Twitter' />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
