import React from 'react';
import { connect } from 'react-redux';
import { SUCCESS_STATUS } from '@mokuroku/mokuroku-commons/dictionaries/statuses';
import { recoverPasswordRequest } from '../../actions/userAuthActions';
import styles from './RecoverPassword.module.scss';
import commonStyles from '../../../theme/common.module.scss';
import RecoverPasswordForm from './RecoverPasswordForm';
import { RECOVER_PASSWORD_REQUEST } from '../../actions/userAuthConstants';

export const RecoverPassword = ({ dispatch, networkData }) => {
  const submitForm = (values) => {
    dispatch(recoverPasswordRequest({ email: values.email }));
  };
  const submitted =
    networkData.response &&
    networkData.response.type === RECOVER_PASSWORD_REQUEST &&
    networkData.response.status === SUCCESS_STATUS;
  const serverError =
    networkData.error && networkData.error.type === RECOVER_PASSWORD_REQUEST && networkData.error.message
      ? networkData.error.message
      : null;

  return (
    <section className={`${commonStyles.mainGrid} ${styles.recoverPassword}`}>
      <div className={styles.block}>
        <h1 className={styles.title}>Help with your password</h1>
        {!submitted ? (
          <>
            <p className={styles.instructions}>
              Did you forget your password? Please input your email address below. We will send you a link to reset your
              password.
            </p>
            <RecoverPasswordForm onSubmit={submitForm} serverError={serverError} />
          </>
        ) : (
          <div className={styles.serverResponse}>Please check your email to reset your password!</div>
        )}
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    networkData: state.networkData,
  };
}

export default connect(mapStateToProps)(RecoverPassword);
