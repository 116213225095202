import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { loginRequest } from '../../actions/userAuthActions';
import styles from './Login.module.scss';
import commonStyles from '../../../theme/common.module.scss';
import LoginForm from './LoginForm';
import FacebookAuth from '../FacebookAuth';
import { SOCIAL_AUTH_FACEBOOK, CUSTOM_AUTH } from '../../../models/constants';
import { LOGIN_REQUEST } from '../../actions/userAuthConstants';

//to prefill form
const testData = process.env.NODE_ENV === 'development' && true; // change to false when testing without data
//to make a mock request to the API that returns success and mock data
const mockRequestSuccess = 'success';
const mockRequestError = '';

export const Login = ({ dispatch, authData, networkData }) => {
  const prefilledData = {
    email: testData ? 'iris@iris.com' : '',
    password: testData ? 'Admin123' : '',
  };
  const serverError =
    networkData.error && networkData.error.type === LOGIN_REQUEST && networkData.error.message && !mockRequestError
      ? networkData.error.message
      : null;
  const [loginMethod, setLoginMethod] = useState(CUSTOM_AUTH); //custom or FB
  const sending = networkData.sending && loginMethod === CUSTOM_AUTH;
  const sendingFB = networkData.sending && loginMethod === SOCIAL_AUTH_FACEBOOK;
  const submitForm = (values) => {
    dispatch(loginRequest({ ...values, loginMethod: CUSTOM_AUTH }, mockRequestSuccess));
  };

  const onFacebookAuthorized = (facebookData) => {
    setLoginMethod(SOCIAL_AUTH_FACEBOOK);
    dispatch(loginRequest({ loginMethod: SOCIAL_AUTH_FACEBOOK, ...facebookData }, mockRequestSuccess));
  };

  if (authData.loggedState) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  } else {
    return (
      <section className={`${commonStyles.mainGrid} ${styles.login}`}>
        <h1 className={styles.title}>Login</h1>
        <p className={styles.instructions}>
          No account yet? <Link to='/signup'>Signup now!</Link>
        </p>
        <FacebookAuth onAuthorized={onFacebookAuthorized} loading={sendingFB}>
          Login with Facebook
        </FacebookAuth>
        <p className={styles.instructions}> or </p>
        <LoginForm onSubmit={submitForm} defaultData={prefilledData} serverError={serverError} loading={sending} />
        <div className={styles.instructions}>
          <Link to='/forgot-password'>Did you forget your password?</Link>
        </div>
      </section>
    );
  }
};

function mapStateToProps(state) {
  return {
    authData: state.authData,
    networkData: state.networkData,
  };
}

export default connect(mapStateToProps)(Login);
