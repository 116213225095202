import React from 'react';
import { useLocation, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ResetPasswordForm from './ResetPasswordForm';
import { resetPasswordRequest } from '../../actions/actions';
import { verifyResetPasswordRequest } from '../../userAuth/actions/userAuthActions';
import { SUCCESS_STATUS } from '@mokuroku/mokuroku-commons/dictionaries/statuses';
import { VERIFY_RESET_PASSWORD_REQUEST } from '../../userAuth/actions/userAuthConstants';
import styles from './ResetPassword.module.scss';
import commonStyles from '../../theme/common.module.scss';
import LoadingSection from '../ui/LoadingSection/LoadingSection';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let dispatched = false;

export const ResetPassword = ({ dispatch, authData, networkData }) => {
  const query = useQuery();
  const mode = query.get('mode');
  const token = query.get('oobCode');
  const submitForm = (values) => {
    dispatch(resetPasswordRequest({ password: values.password, token }));
  };
  const resetPasswordSuccess = (
    <section className={`${commonStyles.mainGrid} ${styles.resetPassword}`}>
      <div className={styles.block}>
        <h1 className={styles.title}>Your password was changed successfuly!</h1>
        <p className={styles.instructions}>Please log in with your new password to access your account.</p>
        <p className={styles.instructions}>
          <Link to='/login'>Go to login</Link>
        </p>
      </div>
    </section>
  );
  const resetPasswordError = (
    <section className={`${commonStyles.mainGrid} ${styles.resetPassword}`}>
      <h1 className={styles.title}>This link has expired</h1>
      <p className={styles.instructions}>
        Please <Link to='/forgot-password'>try resetting your password again.</Link>
      </p>
    </section>
  );
  const loading = <LoadingSection />;

  if (networkData.sending) {
    return loading;
  } else if (networkData.response && networkData.response.status === SUCCESS_STATUS) {
    return resetPasswordSuccess;
  } else if (authData.loggedState) {
    //user is logged in, they want to change their password
    return <ResetPasswordForm onSubmit={submitForm} />;
  } else if (networkData.error && networkData.error.type === VERIFY_RESET_PASSWORD_REQUEST) {
    //The verification for the request failed from provider
    return resetPasswordError;
  } else if (authData.verifiedForResetPassword) {
    //verification for the request was successful
    return <ResetPasswordForm onSubmit={submitForm} />;
  } else if (mode === 'resetPassword' && token) {
    //user is trying to recover password from email, we need to check if token is
    if (!dispatched) {
      dispatch(verifyResetPasswordRequest({ token }));
      dispatched = true;
    }
    return loading;
  } else {
    //if the user is logged and trying to change their password, then loggedState should be true
    //if the user is coming from email link, both mode and oobCode should exist as queryStrings
    //if none of those is true, this is a fake request, redirect to home
    return <Redirect to='/' />;
  }
};

function mapStateToProps(state) {
  return {
    authData: state.authData,
    networkData: state.networkData,
  };
}

export default connect(mapStateToProps)(ResetPassword);
