import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Rellax from 'rellax';
import commonStyles from '../../theme/common.module.scss';
import styles from './HomeGuest.module.scss';
import giftIllustrationImg from './images/mokuroku-home-gift1.svg';
import booksIllustrationImg from './images/mokuroku-home-books1.svg';
import kitchenIllustrationImg from './images/mokuroku-home-kitchen1.svg';
import arrowImg from './images/arrow.svg';
import starImg from './images/star.svg';
import { isNode } from '../../utils/utilities';

const HomeGuest = () => {
  //Scroll effects
  useEffect(() => {
    let rellax;
    if (!isNode()) {
      rellax = new Rellax('.rellax', { breakpoints: [600, 800, 1200] });
    }
    return () => {
      if (!isNode()) {
        rellax.destroy();
      }
    };
  }, []);
  //
  return (
    <div>
      <section className={`${styles.sectionContainer} ${commonStyles.mainGrid} ${styles.giftBlock}`}>
        <article className={`${styles.textBlock}`}>
          <h1 className={`${styles.title}`}>
            Bringing your friends closer to the things you <span className={styles.highlight}>love</span>
          </h1>
          <p className={styles.subtext}>Help your loved ones pick the perfect gift for you.</p>
        </article>
        <div className={styles.buttonContainer}>
          <Link to='/signup' className={styles.c2aButton}>
            Get started now
          </Link>
        </div>
        <aside className={`${commonStyles.mainGrid} ${styles.illustrationBlock} rellax`} data-rellax-speed='4'>
          <img className={styles.giftIllustration} src={giftIllustrationImg} />
          <div className={`${styles.arrowImg} rellax`} data-rellax-speed='7'>
            <img src={arrowImg} />
          </div>
          <img className={`${styles.minigift1} rellax`} data-rellax-speed='-5' src={giftIllustrationImg} />
          <img className={`${styles.minigift2} rellax`} data-rellax-speed='-2' src={giftIllustrationImg} />
        </aside>
      </section>
      <section className={`${styles.sectionContainer} ${commonStyles.mainGrid} ${styles.bookBlock}`}>
        <aside className={styles.illustrationBlock}>
          <img
            className={`${styles.illustrationImg} rellax`}
            data-rellax-percentage='0.5'
            data-rellax-speed='2'
            src={booksIllustrationImg}
          />
          <div className={`${styles.rectangle1} rellax`} data-rellax-speed='-1'></div>
          <div className={`${styles.rectangle2} rellax`} data-rellax-speed='2'></div>
          <div className={`${styles.rectangle3} rellax`} data-rellax-speed='4' data-rellax-xs-speed='1'></div>
        </aside>
        <article className={styles.textBlock}>
          <h1 className={styles.title}>
            A <span className={styles.highlight}>moku</span> is a collection of your favorite things, ready to be shared
          </h1>
          <p className={styles.subtext}>
            Your mokus can have any theme, like a list of books you want to read, or the ingredients for a big recipe
            you want to make.
          </p>
          <p className={`${styles.subtext} ${styles.bold}`}>
            Share your mokus to let others around you know what you like best, what you need, or anything really!
          </p>
        </article>
      </section>
      <section className={`${styles.sectionContainer} ${commonStyles.mainGrid} ${styles.cakeBlock}`}>
        <article className={styles.textBlock}>
          <h1 className={styles.title}>Select the best of the web and save it for yourself and others</h1>
          <p className={styles.subtext}>
            Picking a perfect gift will never be easier! But it is not only about the gifts; sharing your interests,
            wishes, and plans all in a single place let you make new discoveries along the way.
          </p>
        </article>
        <aside className={`${styles.illustrationBlock}`}>
          <img className={styles.illustrationImg} src={kitchenIllustrationImg} />
          <div className={`${styles.star1}`}>
            <img src={starImg} alt='' />
          </div>
          <div className={`${styles.star2}`}>
            <img className={`${styles.scalingStar}`} src={starImg} alt='' />
          </div>
          <div className={`${styles.star3} rellax`} data-rellax-speed='1'>
            <img src={starImg} className={styles.rotatingStar} alt='' />
          </div>
          <div className={`${styles.star4} rellax`} data-rellax-speed='2'>
            <img src={starImg} className={styles.rotatingStar} alt='' />
          </div>
        </aside>
      </section>
    </div>
  );
};

export default HomeGuest;
